













































import { Component, Prop, Mixins, Watch } from 'vue-property-decorator'
import axios from 'axios'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import isEmpty from 'lodash/isEmpty'

import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import snakecaseKeys from 'snakecase-keys'
import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    PasswordConfirmField
  }
})
export default class TopicFormModal extends Mixins(ValidatorConfigMixin) {
  @Prop({ required: true, default: false }) visible!: boolean
  @Prop({ required: true, type: String }) requestUrl!: ''
  @Prop({ required: true, type: String }) eventId!: boolean
  @Prop() selectedTopic!: Record<string, any>
  @Prop() type!: 'create' | 'edit' | ''

  form: Record<string, any> = {
    name: '',
    confirm: '',
    eventId: '',
    errors: {
      name: '',
      confirm: '',
      eventId: ''
    }
  }

  @Watch('visible')
  onVisibleChange (isVisible: boolean) {
    if (isVisible && !isEmpty(this.selectedTopic)) {
      this.form.name = this.selectedTopic.name
    }
  }

  closeModal () {
    this.clearForm()
    this.$emit('close')
  }

  submit () {
    const verb = this.type === 'edit' ? 'put' : 'post'

    this.form.eventId = this.eventId

    axios[verb](this.requestUrl, snakecaseKeys(this.form, { deep: true }))
      .then(() => {
        this.$emit('submitted', this.type)
        this.clearForm()
      })
      .catch(err => this.handleSubmitError(err))
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = response.data.errors
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }

  $refs!: {
    observer: any;
  }

  clearForm () {
    this.form = {
      name: '',
      confirm: '',
      errors: {
        name: '',
        confirm: ''
      }
    }

    this.$refs.observer.reset()
  }
}
